import React from "react";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";

const NotFound = () => {
  return (
    <main>
      <MainMenu />
      <section className="not-found-section">
        <div className="not-found-section-content container">
          <span>Page not found</span>
          <h1 className="heading-primary">Oops!</h1>
          <h1 className="heading-primary">Something went wrong!</h1>
          <p className="paragraph-primary">
            It looks like you're trying to find a page that doesn't exist yet or
            has been moved
          </p>
        </div>
        <CTABanner />
        <Footer />
      </section>
    </main>
  );
};

export default NotFound;
